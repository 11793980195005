html,
body {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}
body {
	background-color: black;
	font-family: Zurich;
	margin: 0;
	padding: 0;
}

/* application container */
.appContainer {
	color: red;
	background-color: white;

	/* PAL 4:3 resolution. */
	width: 720px;
	height: 576px;

	/* https://stackoverflow.com/questions/14123999/center-a-div-horizontally-and-vertically */
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);

	margin: 0;
	padding: 0;

	&::before {
		content: "";
		background-image: url("./tv.jpeg");
		width: 1974px;
		height: 1507px;
		z-index: -1;
		left: 50%;
		top: 50%;
		background-size: cover;
		transform: translate(-50%, -50%);
		position: absolute;
		display: block;
		background-position-x: 22px;
		background-position-y: -20px;
	}
}

img.reference {
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
}
