@use "../fonts/fonts.scss";
@use "sass:list";

$skyGamesPink: #f42f64;

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $skyGamesPink;
}
.skyGames {
	font-family: "SkyGames";
	//background-image: url("https://static.wikia.nocookie.net/sky-gamestar/images/7/74/Sky_Games_05-2012.png");
	background-color: white;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: 92px auto 120px;
}

.skyGames_reference {
	position: absolute;
	left: 0;
	top: 0;
	top: 37px;
	width: 100%;
	height: auto;
	z-index: 999;
	opacity: 0.7;

	pointer-events: none;
}
a {
	&,
	&:focus,
	&:visited {
		color: $skyGamesPink;
	}
	cursor: pointer;
}
$pageBorderWidth: 2px;
$pageBorderColour: #dbdbdb;
// $pageBorderColour: magenta;
$pageBorder: solid $pageBorderColour $pageBorderWidth;

#skyGames_fade {
	z-index: 100;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	pointer-events: none; /* Disable mouse events on the overlay */
	opacity: 1;
	transition: opacity 0.5s ease, visibility 0.5s ease;
	visibility: visible;

	&.done {
		opacity: 0;
		visibility: hidden;
	}
}
:root {
	--tab-overlap: 17px;
}

%shadow {
	box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
}

.skyGamesHeader {
	$tab-overlap: var(--tab-overlap);
	position: relative;
	padding: 52px 62px;
	padding-bottom: 0px;
	display: grid;
	grid-template-rows: 40px;
	grid-template-columns: calc(140px + $tab-overlap) auto;
	h1 {
		margin: 0;
	}
	img {
		width: 88px;
	}
	&::after {
		content: "";
	}

	.skyGamesTabs {
		display: flex;

		.skyGamesTab {
			// background-color: yellow !important;
			// color: green !important;

			font-size: 20px;
			border: $pageBorder;
			text-decoration: none;
			$border-radius: 15px;
			border-top-right-radius: $border-radius;
			border-top-left-radius: $border-radius;
			border-bottom: none;

			margin: 0px calc(0px - $tab-overlap);
			padding: 8px $tab-overlap;
			text-align: center;
			z-index: 0;
			background-color: rgb(231, 231, 231);
			color: #254c9e;
			z-index: 1;

			&.active {
				@extend %shadow;
				color: $skyGamesPink;
				z-index: 1;
				background-color: white;

				z-index: 3;

				& + .skyGamesTab {
					z-index: 2;
				}
			}
		}
	}
}

$leftMargin_width: 60px;
$gameGrid_width: 334px;
$divider_width: 16px;
$gameInfo_width: 233px;

.skyGamesMain {
	@extend %shadow;
	position: relative;
}
/*
$leftMargin_width: a;
$gameGrid_width: b;
$divider_width: c;
$gameInfo_width: d;

720-(b+3c/2+d+a)
*/

.skyGamesMainContainer {
	margin-top: 6px;
	margin-left: $leftMargin_width - calc($divider_width / 2);
	margin-right: 720px - ($leftMargin_width + $gameGrid_width + (3 * calc($divider_width/2)) + $gameInfo_width);

	& > img {
		width: 100%;
	}
	&.split {
		display: grid;
		grid-template-columns: ($gameGrid_width + $divider_width) ($gameInfo_width + $divider_width);
	}
}

@mixin gameArrows($top: false, $right: false, $bottom: false, $left: false) {
	// Default background image
	//background-image: url("game-arrows.svg");

	$arm_length: 20%;
	$full_clip_path: (
		1 0% $arm_length,
		2 $arm_length $arm_length,
		3 $arm_length 0%,
		4 (100%-$arm_length) 0%,
		5 (100%-$arm_length) $arm_length,
		6 100% $arm_length,
		7 100% (100%-$arm_length),
		8 (100%-$arm_length) (100%-$arm_length),
		9 (100%-$arm_length) 100%,
		10 $arm_length 100%,
		11 $arm_length (100%-$arm_length),
		12 0% (100%-$arm_length)
	);
	$clip_path: ();

	$top_ids: 3, 4;
	$right_ids: 6, 7;
	$bottom_ids: 9, 10;
	$left_ids: 1, 12;

	@each $index, $v1, $v2 in $full_clip_path {
		$clip_point: $v1 $v2;
		@if not(
			($top == false and index($top_ids, $index)) or
				($bottom == false and index($bottom_ids, $index)) or
				($left == false and index($left_ids, $index)) or
				($right == false and index($right_ids, $index))
		)
		{
			$clip_path: append($clip_path, $clip_point, "comma");
		}
	}

	clip-path: polygon($clip_path);
}

.skyGames_game {
	position: relative;
	display: flex;
	justify-content: space-around;
	margin: 5%;

	@for $row from 1 through 3 {
		@for $col from 1 through 3 {
			$n: ($row - 1) * 3 + $col;
			&:nth-child(#{$n}) {
				&::after {
					// Apply the mixin with the calculated values to the ::after pseudo-element
					/*
					#{$col $row}
					*/
					@include gameArrows($top: $row != 0, $right: $col != 3, $bottom: $row != 3, $left: $col != 1);
				}
			}
		}
	}

	img {
		position: absolute;

		outline: transparent 5px solid;
		border: transparent 3px solid;
	}

	&::after {
		content: "";
	}

	&.left {
		&::before,
		&::after {
			transform: translateX(100%);
		}
	}
	&.right {
		&::before,
		&::after {
			transform: translateX(-100%);
		}
	}
	&.up {
		&::before,
		&::after {
			transform: translateY(100%);
		}
	}
	&.down {
		&::before,
		&::after {
			width: 84%;
			height: 84%;
			transform: translateY(-100%);
		}
	}

	&:focus,
	&:hover,
	&:focus-visible {
		outline: none;
		img {
			outline-color: $skyGamesPink;
			border-color: white;
		}

		&::after,
		&::before {
			content: "";
		}

		&::after {
			// content: "";
			// position: absolute;
			// top: 50%;
			// left: 50.007455%;

			// z-index: 1;
			// transform: translate(-50%, -46%) rotate(45deg);
			// background-color: white;
			// border: solid $skyGamesPink 5px;

			//clip-path: inset(0 0 0 $arrow-size);

			content: "";
			position: absolute;
			background-image: url("game-arrows.svg");
			width: 135px;
			height: 137px;
			z-index: 1;
			top: -12px;
			left: -15px;
		}

		&::before {
			transform: translate(0, 0);
			transition: transform 0.5s;
			content: "SELECT";
			padding: 0 10px;
			font-size: 15px;
			color: white;
			font-weight: bold;
			background-color: #f42f64;
			background-image: linear-gradient(0deg, rgba(211, 52, 92, 1) 20%, transparent 35%);
			border-radius: 0.5rem;

			position: absolute;
			top: -0.6em;

			outline: white solid;
			z-index: 2;
		}
	}
}

.skyGames_gamesList {
	flex: 1 0 66%;
	position: relative;
}

.skyGames_gameGrid {
	display: grid;
	height: 100%;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;

	> a {
		position: relative;
		margin: 6px;
	}

	img {
		height: 100%;
		width: 100%;
		border-radius: 24px;
	}
}

/*
(jumps 5 times)
waits 10s

3s

0s in
0.18s out
0.375s in
0.555s out
0.75s in

back to start

*/

@keyframes arrowMovement {
	0%,
	100% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(-15px);
	}
}

%animatedArrows {
	animation: arrowMovement 0.5s 5;
}

$arrow_x: -20px;

[class^="skyGamesArrow"] {
	position: absolute;
	top: 50%;
	translate: 0% -50%;
}

.skyGamesArrowLeft {
	left: $arrow_x;
	@extend %animatedArrows;
}

.skyGamesArrowRight {
	rotate: 180deg;
	right: $arrow_x;
	@extend %animatedArrows;
}

.skyGames_gameInfo {
	.gameInfo_container {
		@extend %shadow;
		// height: 332px;
		// width: 233px;

		width: 172px;
		height: 280px;

		padding: 30px 34px;

		border-radius: 13px;

		margin: calc($divider_width / 2);

		// padding: 0.1px;
	}

	.gameInfo_infoEntry {
		$round-border-radius: 0.5em;

		background-color: #c7d4ec;
		height: 100%;
		display: grid;
		grid-template-rows: 140px auto 25px;
		border-bottom-left-radius: $round-border-radius;
		border-bottom-right-radius: $round-border-radius;

		.skyGames_infoImage {
			//margin: 8px 6px;
			width: 160px;
			height: 126px;

			$border_hor: 8px;
			$border_vir: 6px;

			border: solid rgb(190, 190, 190);
			border-bottom-width: $border_hor;
			border-top-width: $border_hor;
			border-left-width: $border_vir;
			border-right-width: $border_vir;
		}

		.infoEntry_gameText {
			margin: 8px 6px;
			position: relative;

			display: flex;
			flex-flow: column;

			p {
				margin: 0;
			}
			.gameText_title {
				color: #254c9e;
				font-size: 130%;
				flex: 1;
			}

			.gameText_blurb {
				color: black;
				font-size: 85%;
				//position: absolute;
				//bottom: 0;
				//left: 0;
			}
		}

		.infoEntry_gameCategory {
			background-color: #255595;
			color: white;
			border-bottom-left-radius: $round-border-radius;
			border-bottom-right-radius: $round-border-radius;
			text-align: center;
			display: flex;
			flex-flow: column;
			justify-content: center;
		}
	}
}

.skyGames_footer {
	//Temp code so i can see it
	// position: relative;
	//-------------------------
	&Container {
		//Temp code so i can see it
		// position: absolute;
		// top: 0;
		// left: 0;
		// z-index: 1000;
		//-------------------------

		display: grid;

		grid-template-columns: 31px repeat(4, 142px);

		@extend %shadow;
		width: 592px;
		height: 37px;
		margin: 21px 55px;
		border-radius: 10px;
		border: #cbcbcb solid;
		border-width: 2px;
		background-image: linear-gradient(180deg, #e8e8e8 40%, #d0d1d5 50%, #d0d1d5 80%, #b6b6b6 90%);

		[class^="skyGames_color"] {
			width: 100%;
			height: 30px;
			display: flex;
			/* justify-content: center; */
			align-items: center;
			text-decoration: none;
			color: black;
			gap: 8px;
			&::before {
				content: "";
				display: block;
				width: 26px;
				height: 26px;
				color: black;
				margin-top: 4px;
				border-radius: 7px;
				box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
				background: linear-gradient(
					180deg,
					rgb(255 255 255 / 49%) 35%,
					rgb(255 255 255 / 12%) 45%,
					rgb(255 255 255 / 12%) 75%,
					rgb(255 255 255 / 0%) 100%
				);
			}
		}

		.skyGames_color {
			&Red {
				grid-column: 2;
				&::before {
					background-color: #e0011e;
				}
			}
			&Green::before {
				background-color: #21a626;
			}
			&Yellow::before {
				background-color: #f4af14;
			}
			&Blue::before {
				background-color: #1bacfd;
			}
		}
	}
}

.test {
	position: relative;
	width: 100px;
	height: 100px;
}

#skyGamesControls {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
