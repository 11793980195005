.epgHeader {
	/*background-color: green;*/
	display: flex;
	flex-direction: row;

	position: absolute;
	top: 34px;
	left: 47px;
}

.epgHeaderItem {
	display: flex;
	flex-direction: column;
	text-decoration: none;

	align-items: center;

	justify-content: flex-end;
	align-content: flex-start;
	flex-wrap: nowrap;

	width: 110px;

	padding-right: 5px;

	font-family: "ZurichTV";
	font-size: 22.5px;

	img {
		width: 72.5px;
		/*background-color: purple;*/
	}

	span {
		color: white;
	}
}

.epgHeader_text {
	height: 16px;
	margin-top: 13px;
	margin-left: -10px;
	color: #00003b;
	h1 {
		color: #00003b;
		margin: 0;
		font-size: 28px;
		margin-top: 3px;
	}
	span {
		font-size: 20px;
	}
}

.headerSelect {
	span {
		color: #2a66e2;
	}

	img {
		filter: invert(54%) sepia(80%) saturate(6475%) hue-rotate(212deg) brightness(92%) contrast(91%);
	}
}

.epgHeaderLogo {
	display: flex;
	flex-direction: column;

	align-items: center;

	justify-content: flex-end;
	align-content: flex-start;
	flex-wrap: nowrap;

	width: 90px;

	padding-right: 0px;

	font-family: "SkyLogo";
	font-size: 26px;

	img {
		width: 75px;
		/*background-color: purple;*/
	}

	span {
		color: white;
	}
}
