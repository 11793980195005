.epgMenuContainer {
	position: absolute;

	left: 26px;
	top: 14px;

	%focusable {
		outline: none;
	}

	%selectedItem {
		background-color: #fcda15;
		cursor: pointer;

		.menuNumber {
			color: #0000b3;
			background-color: #fec200;
		}
		.title {
			color: #0000b3;
		}
	}

	li {
		background-color: #002db6;

		list-style-type: none;

		width: 477px;

		height: 28px;

		margin-bottom: 4.5px;

		.title {
			color: white;
			position: absolute;
			left: 119px;
			font-size: 24px;
		}

		.menuNumber {
			background-color: #00137d;
			color: #758ae5;
			text-decoration: none;

			height: 28px;
			width: 64px;

			position: absolute;
			left: 50px;

			display: flex;
			align-items: center;
			justify-content: center;

			font-size: 24px;
		}

		&:hover,
		&:focus,
		&:focus-within,
		&:focus-visible {
			@extend %focusable;
			@extend %selectedItem;
		}
	}

	a {
		&:hover,
		&:focus,
		&:focus-visible {
			@extend %focusable;
			li {
				@extend %selectedItem;
			}
		}
	}
}
